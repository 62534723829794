import {
  Button,
  Card,
  Center,
  Grid,
  Horizontal,
  Text,
  UnstyledButton,
  Vertical,
} from '../../../../design-system/v2';
import { TemplateModel } from '../../../../generated/api';

interface TemplateCardProps {
  template: TemplateModel;
  onFlowClick: (template: TemplateModel) => void;
  onCloneClick: (template: TemplateModel) => void;
}

export const TemplateCard = ({
  template,
  onFlowClick,
  onCloneClick,
}: TemplateCardProps): JSX.Element => {
  const handleFlowClick = () => {
    onFlowClick(template);
  };

  const handleCloneClick = () => {
    onCloneClick(template);
  };

  return (
    <Card withBorder p="lg" shadow="md" key={template.templateId}>
      <Grid>
        <Grid.Col span="auto">
          <Vertical align="left" spacing="sm">
            <Horizontal spacing="xs">
              <Text variant="subTitle02" color="gray.8">
                {template.name}
              </Text>
              <Text variant="small01" color="gray.7">
                by {template.author}
              </Text>
            </Horizontal>
            <Text variant="bodyShort03" color="gray.6" lineClamp={2}>
              {template.description}
            </Text>
          </Vertical>
        </Grid.Col>
        <Grid.Col span="content">
          <Center h="100%">
            <Horizontal spacing="xl" noWrap>
              <UnstyledButton onClick={handleFlowClick} variant="subtle">
                <Text variant="textLink" color="gray.6">
                  View Flow
                </Text>
              </UnstyledButton>
              <Button onClick={handleCloneClick} variant="outline" size="xs">
                Clone template
              </Button>
            </Horizontal>
          </Center>
        </Grid.Col>
      </Grid>
    </Card>
  );
};
