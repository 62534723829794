import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { CreateWorkflowRunRequest } from '../../../generated/api';
import { workflowApi } from '../../../lib/api';
import { debugRunKeys } from '../debug';
import { workflowsQueryKeys } from '../list/list';

export const useCreateWorkflowRunMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (req: CreateWorkflowRunRequest) =>
      workflowApi.createWorkflowRunV1(workspaceId, workflowId, req),
    {
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: workflowsQueryKeys.all,
        }),
    },
  );
};

export const useGetWorkflowRunDetailsQuery = (workflowId: string, workflowRunId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery(
    workflowsQueryKeys.runsDetail(workspaceId, workflowId, workflowRunId),
    () => workflowApi.getWorkflowRunV1(workspaceId, workflowId, workflowRunId),
    {
      select: res => res.data,
    },
  );
};

export const useStopWorkflowRunMutation = (workflowId: string, workflowRunId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(() => workflowApi.stopWorkflowRunV1(workspaceId, workflowId, workflowRunId), {
    onSuccess: () => {
      queryClient.invalidateQueries(
        debugRunKeys.debugRunStatus(workspaceId, workflowId, workflowRunId),
      );
      queryClient.invalidateQueries(
        workflowsQueryKeys.runsDetail(workspaceId, workflowId, workflowRunId),
      );
      queryClient.invalidateQueries(workflowsQueryKeys.list(workspaceId));
    },
  });
};
