import {
  Button,
  Horizontal,
  Modal,
  MODAL_LEVEL_2_Z_INDEX,
  notifications,
  Text,
  Vertical,
} from '../../../design-system/v2';
import { logger } from '../../../initializers/logging';
import { useSetCurrentWorkspaceSubscriptionFreemiumMutation } from '../../../queries/account/payment-and-subscription';

// Need to use Modal (instead of useConfirmModal) as modal stacking is required
// see https://github.com/orgs/mantinedev/discussions/4825 for more details
export const FreePlanConfirmModal = ({
  opened,
  onClose,
}: {
  opened: boolean;
  onClose: () => void;
}) => {
  const { mutateAsync: updateSubscription } = useSetCurrentWorkspaceSubscriptionFreemiumMutation();

  const handleConfirm = async () => {
    try {
      await updateSubscription();
      onClose();
    } catch (error) {
      logger.error(error);
      notifications.error('Failed to update plan details. Please try again or contact support.');
    }
  };

  return (
    <Modal
      centered
      withCloseButton={false}
      opened={opened}
      onClose={onClose}
      zIndex={MODAL_LEVEL_2_Z_INDEX}
    >
      <Vertical spacing="xl">
        <Text variant="subTitle02">
          By selecting the Free plan, you will lose access to all premium features in MarkovML.
        </Text>
        <Text variant="bodyShort02">Are you sure you want to continue?</Text>
        <Horizontal position="right">
          <Button onClick={onClose}>Go back</Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Horizontal>
      </Vertical>
    </Modal>
  );
};
