import { useParams } from 'react-router-dom';
import { useWorkflowRunContext } from '../../../../contexts/workflows/WorkflowRunContext';
import { Horizontal } from '../../../../design-system/v2';
import { useDebugRunStatusQuery } from '../../../../queries/workflows/debug';
import { WorkflowViewerContainer } from '../viewer/WorkflowViewer.container';
import { WorkflowRunsListContainer } from './WorkflowRunsList.container';

interface WorkflowRunsWrapperProps {
  workflowId?: string;
}

export const WorkflowRunsWrapper = ({ workflowId: wfId = '' }: WorkflowRunsWrapperProps) => {
  const { workflowId = wfId } = useParams();
  const { runId } = useWorkflowRunContext();

  const { data: statusData, isLoading } = useDebugRunStatusQuery(workflowId, runId);

  return (
    <Horizontal noWrap sx={{ flexGrow: 1, overflowY: 'auto' }} h="100%">
      <WorkflowRunsListContainer workflowId={workflowId} />
      <WorkflowViewerContainer
        workflowId={workflowId}
        dag={statusData?.dag}
        isDagLoading={isLoading}
      />
    </Horizontal>
  );
};
