import { DurationUnitType } from 'dayjs/plugin/duration';
import { useEffect } from 'react';
import {
  Horizontal,
  NumberInput,
  Select,
  Text,
  useInputState,
  Vertical,
} from '../../../design-system/v2';
import { convertAnyDurationToSeconds } from '../../../lib/time-format';

const durationOptions: { label: string; value: DurationUnitType }[] = [
  { label: 'Minutes', value: 'minutes' },
  { label: 'Hours', value: 'hours' },
  { label: 'Days', value: 'days' },
  { label: 'Weeks', value: 'weeks' },
  { label: 'Months', value: 'months' },
];

interface PeriodicRunProps {
  setInterval: (value: number) => void;
  defaultDuration?: number;
  defaultDurationUnit?: DurationUnitType;
}

export const PeriodicRun = ({
  setInterval,
  defaultDuration = 30,
  defaultDurationUnit = 'minutes',
}: PeriodicRunProps) => {
  const [duration, setDuration] = useInputState(defaultDuration);
  const [durationUnit, setDurationUnit] = useInputState<DurationUnitType>(defaultDurationUnit);

  useEffect(() => {
    setInterval(convertAnyDurationToSeconds(duration, durationUnit));
  }, [duration, durationUnit, setInterval]);

  return (
    <Vertical spacing="0">
      <Text align="left" variant="subTitle05">
        Run every
      </Text>
      <Horizontal w="100%" noWrap>
        <NumberInput
          value={duration}
          onChange={setDuration}
          label=""
          rightSection=""
          ariaLabel="s"
          placeholder=""
          description=""
          required
          w="100%"
        />
        <Select
          withinPortal
          ariaLabel="Run every"
          label=""
          placeholder="Pick value"
          value={durationUnit}
          onChange={(value: DurationUnitType) => {
            setDurationUnit(value);
          }}
          options={durationOptions}
          w="100%"
        />
      </Horizontal>
    </Vertical>
  );
};
