import { Badge, Horizontal, Text, Vertical } from '../../../../design-system/v2';
import { WorkflowRun } from '../../../../generated/api';
import { useGetWorkflowDAGQuery } from '../../../../queries/workflows/dag';
import { useDebugRunStatusQuery } from '../../../../queries/workflows/debug';
import { getWorkflowRunItemDetails } from './util';

interface WorkflowRunDetailsOverviewProps {
  run: WorkflowRun;
  withExecutionTypeLabel: boolean;
  activeFlowBadge?: boolean;
}

export const WorkflowRunDetailsOverview = ({
  run,
  withExecutionTypeLabel,
  activeFlowBadge,
}: WorkflowRunDetailsOverviewProps) => {
  const { data } = useGetWorkflowDAGQuery(run.workflowId);
  const { data: runStatus } = useDebugRunStatusQuery(run.workflowId, run.workflowRunId);

  const {
    title,
    executionType,
    info = '',
    subtitle = '',
    subtitleColor = 'gray.6',
  } = getWorkflowRunItemDetails(run);

  const executionTypeLabel = (
    <Text variant="small02" color="gray.6">
      {executionType}
    </Text>
  );

  return (
    <Vertical w="100%" spacing="xs">
      <Horizontal noWrap>
        <Text variant="subTitle02" color="gray.9">
          {title}
        </Text>
        {run.debugRun ? (
          <Badge variant="light" bg="yellow.0" color="yellow.7" size="small" px="sm">
            <Text variant="small03" color="yellow.7" tt="capitalize">
              Sample run
            </Text>
          </Badge>
        ) : null}
        {activeFlowBadge && runStatus?.dagHash === data?.dagHash ? (
          <Badge variant="dot" bg="gray.0" color="green.5">
            <Text variant="small03" color="gray.7" tt="capitalize">
              Active flow
            </Text>
          </Badge>
        ) : null}
      </Horizontal>
      <Horizontal w="100%" spacing="xxl" position="apart" align="end" noWrap>
        <Horizontal noWrap spacing="xs" align="end" h={28}>
          <Text variant="small02" color={subtitleColor}>
            {subtitle}
          </Text>
          {withExecutionTypeLabel && (
            <>
              {subtitle ? (
                <Text variant="subTitle01">
                  {/* Centered dot separator */}
                  {'\u00B7'}
                </Text>
              ) : null}
              {executionTypeLabel}
            </>
          )}
        </Horizontal>
        {info}
      </Horizontal>
    </Vertical>
  );
};
