import { Button, notifications } from '../../../../../design-system/v2';
import { WorkflowRunStatus } from '../../../../../generated/api';
import { useStopWorkflowRunMutation } from '../../../../../queries/workflows/runs/runs';

export interface StopRunButtonProps {
  workflowRunStatus: WorkflowRunStatus;
  workflowId: string;
  workflowRunId: string;
}
export const StopRunButton = ({
  workflowRunStatus,
  workflowId,
  workflowRunId,
}: StopRunButtonProps) => {
  const { mutateAsync: stopWorkflowRun, isLoading } = useStopWorkflowRunMutation(
    workflowId,
    workflowRunId,
  );

  const handleStop = async () => {
    try {
      await stopWorkflowRun();
      notifications.success('Successfully stopped the current workflow run');
    } catch (e) {
      notifications.error('Unable to stop current workflow run. Please try again');
    }
  };

  return (
    <Button
      variant="primary"
      onClick={handleStop}
      disabled={
        ![
          WorkflowRunStatus.NotFound,
          WorkflowRunStatus.Created,
          WorkflowRunStatus.Running,
        ].includes(workflowRunStatus)
      }
      color="red"
      loading={isLoading}
    >
      Stop Run
    </Button>
  );
};
