import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useCallback } from 'react';
import { PaymentSubscriptionType } from '../../../generated/api';
import { useCreateCheckoutSessionMutation } from '../../../queries/account/payment-and-subscription';

// TODO: Replace test key with actual key from env
const STRIPE_KEY =
  'pk_test_51MqJVYDy8addG8nvHkvl2eKRnGfnQ53IzDGv8hq6OdVXDEETKtIDacaRiHduuiYQPy1tiSfVfoVf0SxkXUdtpyww00aP6aLSfr';
const stripePromise = loadStripe(STRIPE_KEY);

interface CheckoutFormProps {
  subscriptionType: PaymentSubscriptionType;
  onComplete?: () => void;
}

export const SubscriptionPlanCheckoutForm = ({
  subscriptionType,
  onComplete,
}: CheckoutFormProps) => {
  const { mutateAsync: createCheckoutSession } = useCreateCheckoutSessionMutation();

  const fetchClientSecret = useCallback(async () => {
    const res = await createCheckoutSession(subscriptionType);
    return res.data.clientSecret;
  }, [subscriptionType]);

  const options = {
    fetchClientSecret,
    onComplete,
  };

  return (
    <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
      <EmbeddedCheckout />
    </EmbeddedCheckoutProvider>
  );
};
