import {
  Box,
  Divider,
  Flex,
  Horizontal,
  useMarkovTheme,
  Vertical,
} from '../../../../design-system/v2';
import { WorkflowRun } from '../../../../generated/api';
import { getRunArtifact, getWorkflowRunIcon } from './util';
import { WorkflowRunDetailsOverview } from './WorkflowRunDetailsOverview';

interface WorkflowListItemProps {
  run: WorkflowRun;
  isUpcoming?: boolean;
}

export const WorkflowRunListItem = ({ run, isUpcoming = false }: WorkflowListItemProps) => {
  const theme = useMarkovTheme();

  const { status } = run;
  const icon = getWorkflowRunIcon(status, theme);

  return (
    <Vertical
      p="lg"
      sx={theme => ({ border: `1px solid ${theme.colors.gray[2]}`, borderRadius: theme.radius.md })}
    >
      <Horizontal w="100%" align="start" noWrap>
        <Box pt="xs">{icon}</Box>
        <WorkflowRunDetailsOverview
          run={run}
          withExecutionTypeLabel={!isUpcoming}
          activeFlowBadge
        />
      </Horizontal>
      <Divider />
      <Flex justify="end">{getRunArtifact(run)}</Flex>
    </Vertical>
  );
};
