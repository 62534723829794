import { useQueryClient } from '@tanstack/react-query';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { Modal, MODAL_LEVEL_2_Z_INDEX, Text } from '../../../design-system/v2';
import { PaymentSubscriptionType } from '../../../generated/api';
import { paymentAndSubscriptionKeys } from '../../../queries/account/payment-and-subscription';
import { SubscriptionPlanCheckoutForm } from './SubscriptionPlanCheckoutForm';

interface PaidPlanCheckoutModalProps {
  opened: boolean;
  onClose: () => void;
  subscriptionType: PaymentSubscriptionType;
}

export const PaidPlanCheckoutModal = ({
  opened,
  onClose,
  subscriptionType,
}: PaidPlanCheckoutModalProps): JSX.Element => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  const handleCompleteCheckout = () => {
    queryClient.invalidateQueries({
      queryKey: paymentAndSubscriptionKeys.getCurrentSubscription(workspaceId),
    });
  };

  return (
    <Modal
      centered
      opened={opened}
      onClose={onClose}
      title={<Text variant="subTitle01">Upgrade plan</Text>}
      size="80vw"
      zIndex={MODAL_LEVEL_2_Z_INDEX}
    >
      <SubscriptionPlanCheckoutForm
        subscriptionType={subscriptionType}
        onComplete={handleCompleteCheckout}
      />
    </Modal>
  );
};
