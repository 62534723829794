import { startCase } from 'lodash';
import {
  Button,
  Horizontal,
  Text,
  Vertical,
  closeAllModals,
  notifications,
  openModal,
} from '../../../../design-system/v2';
import { WorkflowStatus } from '../../../../generated/api';
import { logger } from '../../../../initializers/logging';
import { useUpdateScheduledWorkflowStateMutation } from '../../../../queries/workflows/debug';

const getToggleActionName = (workflowStatus: WorkflowStatus) =>
  workflowStatus === WorkflowStatus.Enabled ? 'remove' : 'resume';

interface UpdateScheduleStatusModalProps {
  workflowId: string;
  workflowStatus: WorkflowStatus;
}

const UpdateScheduleStatusModal = ({
  workflowId,
  workflowStatus,
}: UpdateScheduleStatusModalProps): JSX.Element => {
  const action = getToggleActionName(workflowStatus);

  const {
    mutateAsync: updateScheduleStatus,
    isError,
    isLoading: isSaving,
  } = useUpdateScheduledWorkflowStateMutation(workflowId);

  const handleClick = async () => {
    try {
      await updateScheduleStatus(
        workflowStatus === WorkflowStatus.Enabled
          ? WorkflowStatus.Disabled
          : WorkflowStatus.Enabled,
      );
      notifications.success(`Workflow schedule successfully ${action}d`);
      closeAllModals();
    } catch (error) {
      notifications.error(`Unable to ${action} scheduling workflow runs. Please try again.`);
      logger.error(error);
    }
  };

  const handleCancel = () => {
    closeAllModals();
  };

  const message =
    workflowStatus === WorkflowStatus.Enabled
      ? `Are you sure you want to remove the current schedule for this workflow? Any scheduled runs will be cancelled.`
      : 'Are you sure you want to resume scheduled workflow runs?';

  return (
    <Vertical>
      <Text variant="bodyShort02">{message}</Text>
      <Horizontal mt="xl" position="right">
        <Button variant="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleClick} loading={isSaving}>
          {isError ? 'Retry' : 'Confirm'}
        </Button>
      </Horizontal>
    </Vertical>
  );
};

export const useUpdateScheduleStatusModal = (
  workflowId: string,
  workflowStatus: WorkflowStatus,
) => {
  const open = () =>
    openModal({
      title: (
        <Text variant="subTitle01" component="div">
          {startCase(getToggleActionName(workflowStatus))} schedule
        </Text>
      ),
      children: (
        <UpdateScheduleStatusModal workflowId={workflowId} workflowStatus={workflowStatus} />
      ),
    });

  return { open };
};
