import { useMemo, useState } from 'react';
import { useToggle } from '../../../design-system/v2';
import { DEFAULT_PAGE_SIZE } from '../../../design-system/v2/core/data-display/table/util';
import { ArtifactStateType, ExecutionType, Operator } from '../../../generated/api';
import { useGetWorkflowsQuery } from '../../../queries/workflows/list/list';
import { WorkflowsList } from './List';

interface WorkflowsListContainerProps {
  state?: ArtifactStateType;
}

enum FilterableFields {
  ARTIFACT_STATE = 'artifactState',
  SCHEDULE_STATUS = 'scheduleStatus',
}

export const WorkflowsListContainer = ({ state }: WorkflowsListContainerProps) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [showOnlyScheduled, toggleShowOnlyScheduled] = useToggle();

  const filters = useMemo(() => {
    const artifactStateFilter = {
      field: FilterableFields.ARTIFACT_STATE,
      operator: Operator.In,
      value: state,
    };
    const scheduledOnlyFilter = {
      field: FilterableFields.SCHEDULE_STATUS,
      operator: Operator.In,
      value: ExecutionType.Scheduled,
    };

    const applicableFilters = [];

    if (state) {
      applicableFilters.push(artifactStateFilter);
    }

    if (showOnlyScheduled) {
      applicableFilters.push(scheduledOnlyFilter);
    }

    return applicableFilters;
  }, [state, showOnlyScheduled]);

  const { data, isError, isLoading, isFetching, refetch } = useGetWorkflowsQuery({
    filters,
    start: (currentPage - 1) * DEFAULT_PAGE_SIZE,
    searchQuery: searchText,
  });

  const handleShowOnlyScheduledCheckboxToggle = () => {
    toggleShowOnlyScheduled();
  };

  const handleSearchTextChange = (value: string) => {
    setSearchText(value);
    setCurrentPage(1);
  };

  return (
    <WorkflowsList
      isLoading={isLoading || isFetching}
      searchText={searchText}
      onSearchTextChange={handleSearchTextChange}
      showOnlyScheduled={showOnlyScheduled}
      onShowOnlyScheduledCheckboxToggle={handleShowOnlyScheduledCheckboxToggle}
      onReload={refetch}
      workflows={data?.response}
      hasError={isError}
      state={state}
      currentPage={currentPage}
      handlePageChange={setCurrentPage}
      totalRows={data?.numRecords ?? 0}
    />
  );
};
